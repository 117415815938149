.light {
    background-color: #FFFF;
    color: #0B0C0D;
}

.dark {
    background-color: #212426;
    color: #D0DFF2;
}
.navigationBar {
    background-color: #212426;
    border-bottom: 6px solid #009688;    
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;    
}
.navlight {
    background-color: #009688;
}
.navigationBar .links {
    margin: 10px;
    padding: 10px;
    text-decoration: none;
    background-color: #009688;
    border-radius: 5px;
    color: #D0DFF2;
    border-bottom:none;
    
}
.navlight .links {
    border: #A3B2BF 2px solid;
}
.homeIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 10px;
    border-radius: 5px;
    font-size: 2.1rem;
    background-color: #009688;
    color: #D0DFF2;
    
}

.navigationBar button {
    font-size: 1.3rem;
    border: none;
}

.logo {
    background-color: #009688;
    width: 60px;
    height: 60px;
    position: absolute;
    left: 20px;
    top: 20px;
    border-radius: 5px;
}
.logo p {
    color: #D0DFF2;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 15px auto;
}
.dnllight {
    background-color: #009688;
}
.dnldark {
    background-color: #212426;
    color: #D0DFF2;
}