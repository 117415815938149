.toros {
    width: 500px;
    height: 300px;
    padding-top: 20px;
    margin: 0px auto;
    
}
.toro1 {
    width: 200px;
    height: 200px;
    border: 10px dashed #ff0063;
    border-radius: 50%;
    position: relative;
    left: 90px;
    animation: spin ;
    animation-duration: 80s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.toro2 {
    width: 200px;
    height: 200px;
    border: 10px dashed #ffbd1f;
    border-radius: 50%;
    position: relative;
    left: 210px;
    top: -220px;
    transform-box: fill-box;
    transform-origin: center;
    animation: spin ;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from { 
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}