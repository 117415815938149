
.footerlight {
    width: 100%;
    height: 200px;
    border-top: 6px solid #a3b2bf;
    background-color: #009688;
    color: #d0dff2;
}
.footerdark {
    width: 100%;
    height: 200px;
    border-top: 6px solid #009688;
    background-color: #212426;
    color: #d0dff2;
}