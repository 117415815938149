.article {
    padding-top: 50px;
}
.singleArt {
    display: grid;
    grid-template-columns: 300px auto;
    gap: 50px;
    justify-content: center;
    
}
.capImage img {
    width: 300px;
    height: 300px;
    margin-top: 120px;
}
.cita {
    font-style: italic;
    padding: 100px 0px;
    font-size: 0.96rem;
}

.referencias {
    text-align: left;
    font-size: 0.96rem;
    padding-top: 60%;
    border-bottom: 1px solid #009688;
    border-left: #009688 1px solid;
    padding: 60% 1.2rem 0% 1.2rem;
}
.columText h2 {
    font-size: 2.5rem;
    line-height: 3rem;
}
.columText {
    max-width: 600px;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: left;
}