.intro {
    padding-bottom: 150px;
}
.introBody {
    padding: 2.6rem 0rem;
    
}
.intro h1{
    color: #009688;
    margin-top: 0px;
    padding-top: 50px;
    text-transform: uppercase;
    font-family: 'Cinzel', sans-serif;
}

.line1 {
    max-width: 900px;
    height: 5px;
    margin: 0px auto;
    border-top: #009688 solid 4px;
    border-bottom: #009688 solid 2px;
}
.line2 {
    max-width: 900px;
    height: 5px;
    margin: 0px auto;
    border-bottom: #009688 solid 4px;
    border-top: #009688 solid 2px;
}