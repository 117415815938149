.aboutPage  {
    padding-bottom: 200px;
    
}
.adark {
    background-color: #212426;
}

.aboutPage h1 {
    margin-top: 0;
}
.aboutme {
    justify-content: center;
}
.aboutme img {
    padding: 100px;
}
.cv {
    border: #009688 solid 10px;
    max-width: 800px;
    padding: 100px;
    margin: 0px auto;
}
.formacion {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    color: #009688;
    font-size: 1.3rem;
}
.formacion h4 {
    text-align: left;
}
.formacion p {
    margin-top: 20px;
}
.aboutPage .flight {
    color: #212426;
}

