.homePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}
.homePage h1 {
    max-width: 600px; 
    margin: 0px auto;
    font-family: 'Cinzel', sans-serif;
    font-size: 2rem;
    padding: 100px 10px;
}
.title {
    color: #a3b2bf;
    border-radius: 10px 10px 0 0;
    letter-spacing: 1.5px;
    line-height: 3.6rem;
}
.homePage .titlelight {
    color: #5f6a73;
}
.portada {
    margin: 50px auto;
    border-radius: 10px;
}

.autor {
    color: #a3b2bf;
    font-size: 1.2rem;
    padding: 1.2rem;
    width: 200px;
    position: relative;
    margin: 100px auto 0 auto ;
    
}
.homePage .alight {
    color: #D0DFF2;
}