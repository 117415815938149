.App {
  text-align: center;
}
strong {
  color: #009688;
}

/* Color Theme Swatches in Hex */
color-1-hex { color: #D0DFF2; }
color-2-hex { color: #5F6A73; }
color-3-hex { color: #A3B2BF; }
color-4-hex { color: #0B0C0D; }
color-5-hex { color: #212426; }

/* Color Theme Swatches in RGBA */
color-1-rgba { color: rgba(208, 223, 242, 1); }
color-2-rgba { color: rgba(95, 106, 115, 1); }
color-3-rgba { color: rgba(163, 178, 191, 1); }
color-4-rgba { color: rgba(11, 12, 13, 1); }
color-5-rgba { color: rgba(33, 36, 38, 1); }

/* Color Theme Swatches in HSLA */
color-1-hsla { color: hsla(213, 56, 88, 1); }
color-2-hsla { color: hsla(206, 9, 41, 1); }
color-3-hsla { color: hsla(207, 17, 69, 1); }
color-4-hsla { color: hsla(210, 8, 4, 1); }
color-5-hsla { color: hsla(204, 7, 13, 1); }

