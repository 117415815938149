.articlePage h1{    
    color: #009688;
    margin-top: 0px;
    padding-top: 50px;
    text-transform: uppercase;
    font-family: 'Cinzel', sans-serif;    
}
.capTitle {
    font-family: 'Cinzel';
    color: #009688;
    font-size: 1.4rem;
}

.articleExcerpt {
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    margin:0px auto  ;
    padding: 50px 0;
}

.articleExcerpt img {
    width: 300px;
    height: 300px;
    margin: auto;
}
.articleTitle {
    margin: 0px auto;
    padding: 20px;
    max-width: 760px;
    text-transform: lowercase;
    text-transform: capitalize;
}
.abstract {
    max-width: 600px;
    text-align: left;
    padding: 0px 1rem;
    text-transform: none;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.abstract strong {
    color: #009688;
}
.articleTitle a {
    text-decoration: none;
    background-color: #009688;
    color: #D0DFF2;
    padding: 10px;
    border-radius: 10px;
}
.intro {
    padding: 1.3rem;
}
.intro p{
    max-width: 600px;
    margin: 0px auto;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
p + p {
    text-indent: 1rem;
    initial-letter: capitalize;
}